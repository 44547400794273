import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import Link from '@nib-components/link';
import Section from '@nib-components/section';
import { Stack } from '@nib/layout';

import Layout from '../components/Layout';
import metrics from '../metrics';

const UlBlank = styled.ul`
  list-style-type: none;
`;

const LiMargined = styled.li`
  margin-bottom: 10px;
`;

const title = 'Privacy Policy';
const metaDescription =
  'This page explains how we comply with privacy requirements and sets minimum standards ' +
  'for how we deal with personal info collected and used by nib.';

const HeadingSection = (): JSX.Element => {
  return (
    <Stack space={{ xs: 2, sm: 3 }}>
      <Heading size={{ xs: 2, sm: 1 }} component="h1" color="trueGreen">
        nib Group and Privacy
      </Heading>
      <Copy measure={false}>
        This information explains how we comply with applicable privacy requirements and sets out
        minimum standards for how we deal with personal information collected and used by the nib
        Group to maintain individual privacy, including maintaining confidentiality and ensuring
        security. We may occasionally update this information. You should read this in conjunction
        with any specific privacy policies for our different businesses and locations, available on
        their websites, or in privacy notices provided to you based on your relationship with us.
      </Copy>
      <Copy measure={false}>
        In this Privacy Policy, &quot;<strong>we, our, us or nib Group</strong>&quot; means nib
        holdings limited, ABN 51 125 633 856 and its related entities in the nib Group of companies
        in Australia and other countries including, in the case of the European Economic Area (EEA),
        nib Travel Services Europe Limited and nib Travel Services IrelandLimited, and in the UK,
        nib Travel Services Europe (UK Branch). nib Group’s system for complying with applicable
        privacy requirements includes:
      </Copy>
      <ul>
        <li>
          <p>our commitment to be responsible with your information and treat it with care;</p>
        </li>
        <li>
          <p>
            our adherence with privacy requirements, including privacy laws, contractual agreements,
            and our policies and standards;
          </p>
        </li>
        <li>
          <p>
            our resources allocated to privacy and data protection, including our technology systems
            and services;
          </p>
        </li>
        <li>
          <p>our procedures to prevent and respond to any data breach;</p>
        </li>
        <li>
          <p>and our people and the training and education we undertake.</p>
        </li>
      </ul>
      <Copy measure={false}>
        In the event of any conflict between the English language version of this Privacy Policy and
        other language versions, the English language version will prevail.
      </Copy>
    </Stack>
  );
};

const CollectPersonalInformationSection = (): JSX.Element => {
  return (
    <Stack space={{ xs: 2, sm: 3 }}>
      <Heading size={{ xs: 3, sm: 2 }} component="h2" color="trueGreen">
        Collecting your personal information
      </Heading>
      <Copy measure={false}>
        The businesses operated by the nib Group of companies provide a range of services, including
        health, life and travel insurance, support coordination and National Disability Insurance
        Scheme (NDIS) plan management services, and provide information in relation to health care
        and health care providers, disability services and other services.
      </Copy>
      <Heading size={{ xs: 4, sm: 3 }} component="h3" color="trueGreen">
        The types of information we may collect
      </Heading>
      <Copy measure={false}>
        The types of personal information we collect will depend on your relationship with us, such
        as whether you are an nib customer, website or app user, job applicant, employee (in
        applicable jurisdictions), a claimant, a health service provider or another third party
        (such as a financial adviser or an existing or potential business partner). If you are a nib
        Group customer, the types of personal information we collect will further depend on the type
        of product or service (including the type of policy) we provide to you.
      </Copy>
      <Copy measure={false}>
        When you deal with us, we may collect personal information about or relating to you such as:
      </Copy>
      <Copy measure={false}>
        (a) your name, contact details, gender and other information about your circumstances and
        preferences including about your health or medical history, health related information such
        as your diet and lifestyle, financial information (such as your salary for certain life
        insurance products), government related identifiers, residency, travel visa and employment
        details, professional accreditations, bank account and credit card details, information
        about your use of our products, services, websites and mobile applications, and in some
        cases, your biometric information (such as your voiceprint or fingerprint);
      </Copy>
      <Copy measure={false}>
        (b) where disability services are applicable, information such as details of your
        disability, physical or mental health and support requirements, identification numbers
        associated with you, details of your guardian/nominee or support coordinator including
        names, addresses and contact details; details of your nominated service providers and bank
        account details for payment of invoices. For more detailed information on how we manage
        personal information related to disability services provided through our nib Thrive
        business, please refer to the{' '}
        <Link href="https://www.nib.com.au/docs/thrive-privacy-policy" target="_blank">
          nib Thrive Privacy Policy
        </Link>
        ;
      </Copy>
      <Copy measure={false}>
        (c) if you hold nib securities, information relating to you and your security holding;
      </Copy>
      <Copy measure={false}>
        (d) and details about your interactions with us, like phone calls and emails, as well as
        information about your use of our website or applications using cookies or other digital
        tracking technology.
      </Copy>
      <Heading size={{ xs: 4, sm: 3 }} component="h3" color="trueGreen">
        Who we collect this information from
      </Heading>
      <Copy measure={false}>
        We collect this information when we ask you for it and you provide it, including when you
        visit our website or social media pages, become one of our representatives or business
        partners, apply to receive products or services from us, use our products and services, when
        an insurance claim is lodged by you or on your behalf, or where applicable, from employees
        or prospective employees. We may also collect information about you from third parties, such
        as your insurer for travel insurance, doctor, treating hospital or other health service
        provider, or recruitment agency, if it is not reasonable or practicable to collect the
        information from you, or where you provide your consent for us to do so. This may also
        include other people or organisations who might be representing you, (such as your next of
        kin, nominated representative, legal guardian or power of attorney), including a person who
        takes out an insurance policy on your behalf (ie your employer) or under which you are
        covered, or any person assisting or representing us, including any recruitment or vetting
        agencies.
      </Copy>
      <Copy measure={false}>
        We may also collect your personal information from third parties who provide analytics
        services to nib, including predicted health outcomes based on identified health, lifestyle
        and demographic factors, as well as publicly available sources such as internet search
        engines or social networking services. When we do so, we ensure that we have a legal basis
        for using your personal information, such as to enable us to contact you and offer our
        products and services to you. We may also collect personal information about others such as
        parents and guardians, carers or authorised representatives of third parties.
      </Copy>
      <Copy measure={false}>
        If you are a recognised health care provider, We may collect your personal information from
        databases and directories to support our business processes, including to ensure claims are
        dealt with efficiently, to investigate complaints or to conduct fraud-prevention checks.
      </Copy>
      <Copy measure={false}>
        Like many companies, we use technology and tools that tell us when a computer or device has
        visited or accessed our content, including information such as server address or IP address.
        Those tools include services from search engines and other companies that help us to tailor
        our products and services to better suit our customers and potential customers. Search
        engines provide facilities to allow you to indicate your preferences in relation to the use
        of those tools in connection with computers and other devices controlled or used by you. Our
        mobile applications may also collect precise location information from your device if you
        consent to the collection of this information, and we will always respect your preferences
        including if you choose to withdraw your consent at any time.
      </Copy>
      <Heading size={{ xs: 4, sm: 3 }} component="h3" color="trueGreen">
        Insurance policies with more than one insured person or purchased on behalf of another
        person
      </Heading>
      <Copy measure={false}>
        If you have a Couples or Family health insurance policy with us, or hold a policy that has
        been taken out on your behalf, we may collect your information from the policyholder or
        person or employer who took out the policy on your behalf. The policyholder will also have
        access to all the information on the policy, including those of their partner and any
        dependants.
      </Copy>
      <Copy measure={false}>
        If you have a travel insurance policy that names more than one adult on your Certificate of
        Insurance, or where your policy has been taken out by another person on your behalf, we may
        collect information from them about you.
      </Copy>
      <Copy measure={false}>
        If you have a life insurance policy that has been taken out by another person (i.e. your
        employer) on your behalf, we may collect information from them about you.
      </Copy>
      <Copy measure={false}>
        As a policyholder or person providing information about a member or person, we expect, and
        will reasonably assume, that:
      </Copy>
      <ul>
        <li>
          <p>
            you have obtained their consent to give nib their personal information, and for us to
            use that information as set out in this privacy policy and as otherwise permitted by
            law;
          </p>
        </li>
        <li>
          <p>you have told them you have provided their personal information to nib;</p>
        </li>
        <li>
          <p>
            you have made them aware of the information set out in this privacy policy and informed
            them of how they can access nib’s privacy policy;
          </p>
        </li>
        <li>
          <p>
            and you have told them they are entitled to access their information by contacting us.
          </p>
        </li>
      </ul>
      <Copy measure={false}>
        For health insurance, if a policyholder lodges a claim on your behalf, or for travel
        insurance, if a co-insured adult lodges a claim on your behalf, we act in reliance on the
        above representations given by that person. Unless you tell us otherwise when we notify you
        of the claim lodgement, we assume you have given your consent to the policyholder or
        co-insured to provide all the information we need to process your claim.
      </Copy>
      <Copy measure={false}>
        In relation to disability services we provide that may involve authorised representatives,
        such as if a family member or support coordinator acts on your behalf, we rely on
        representations made on your behalf. If you ask us to assist with management in relation to
        these services, we will ask you to provide your consent to share your personal information
        with third parties.
      </Copy>
      <Copy measure={false}>
        For life insurance, if a beneficiary of your policy (or their representative) lodges a claim
        on your behalf, we act in reliance on the above representations given by that person.
      </Copy>

      <Heading size={{ xs: 4, sm: 3 }} component="h3" color="trueGreen">
        If you do not provide us with your personal information
      </Heading>
      <Copy measure={false}>
        You may choose not to provide us with your personal information. There are some
        circumstances where you can deal with us anonymously or use a pseudonym, such as if you are
        seeking general information about our products or services rather than a customised
        individual quote. Where we do not have your personal information (or personal information of
        an insured person, a participant of special services, a person requesting assistance or
        accessing products/services), or if you have withdrawn consent (when consent is the primary
        legal basis for handling your information), we may not be able to contact you, process your
        requests or employment application, or provide our services to you including providing
        insurance or other assistance, processing an insurance claim or pay invoices.
      </Copy>
    </Stack>
  );
};

const UsingPersonalInformationSection = (): JSX.Element => {
  return (
    <Stack space={{ xs: 2, sm: 3 }}>
      <Heading size={{ xs: 3, sm: 2 }} component="h2" color="trueGreen">
        Using your personal information
      </Heading>
      <Heading size={{ xs: 4, sm: 3 }} component="h3" color="trueGreen">
        How nib uses your personal information
      </Heading>
      <Copy measure={false}>
        Generally, we use your personal information for our business and activities, and in our
        efforts to expand and improve our business. Examples include:
      </Copy>
      <ul>
        <li>
          <p>
            to identify you, and respond to and process your requests for information and provide
            you with a product or service;
          </p>
        </li>
        <li>
          <p>
            to determine your eligibility to provide or receive an nib health or related product or
            service, or a travel insurance service, including to determine any existing medical
            conditions, and to manage our relationship with you including where relevant, providing
            you with a quote or managing insurance related and other services being provided by or
            to you;
          </p>
        </li>
        <li>
          <p>
            to administer and provide insurance services, including health management programmes,
            emergency assistance, and to manage your and our rights and obligations (and those of
            insured persons) in relation to insurance services, including dealing with you or an
            insured person in connection with an insurance proposal, policy, or claim;
          </p>
        </li>
        <li>
          <p>
            to provide support coordination and NDIS plan management in relation to disability
            services, including payment of invoices;
          </p>
        </li>
        <li>
          <p>
            to recommend updates to insurance policies to ensure adequate coverage for services
            beneficial to you;
          </p>
        </li>
        <li>
          <p>
            to administer promotional programmes and scholarships, such as: exclusive member offers,
            competitions or university research scholarships sponsored by nib, when you have
            provided consent as part of your application;
          </p>
        </li>
        <li>
          <p>
            to conduct business processing functions including providing personal information to our
            related bodies corporate, contractors, service providers, underwriting partners or other
            third parties including those making referrals to us and to our strategic, distribution
            and “whitelabel partners” who market and sell our products and services to their
            customers under their own brand;
          </p>
        </li>
        <li>
          <p>
            to prepare internal reports and conduct market research for the purposes of improving
            our products, services and internaloperations, including to improve our online services,
            manage our servers and websites, and to collect and analyse statistical information;
          </p>
        </li>
        <li>
          <p>
            to provide you with advice or information relating to your policy or needs, including
            insurance needs;
          </p>
        </li>
        <li>
          <p>to manage complaints and disputes, and report to dispute resolution bodies;</p>
        </li>
        <li>
          <p>
            to operate programs and forums in different media in which you are able to share
            information, including your personal information, with us and publicly (on the terms
            applicable);
          </p>
        </li>
        <li>
          <p>to manage, train and develop our employees and representatives;</p>
        </li>
        <li>
          <p>for a business or professional relationship we may have with you;</p>
        </li>
        <li>
          <p>if you apply for employment with us, to consider your application;</p>
        </li>
        <li>
          <p>
            to carry out credit checks, credit reporting and other background checks; for debt
            recovery purposes;
          </p>
        </li>
        <li>
          <p>
            where you are a health service provider or emergency assistance provider, to manage our
            relationship with you and your relationship with our members, which may include engaging
            agents to perform this function on nib’s behalf. Other ways nib may use personal
            information of health service providers includes for billing purposes, investigating and
            resolving member or regulator enquiries and complaints, and creating and providing
            access to directory services and costs information to members and other third parties;
          </p>
        </li>
        <li>
          <p>to undertake data anlytics, including in relation to de-identified data;</p>
        </li>
        <li>
          <p>
            to provide services linked with telehealth services offered by nib so that our members
            can enjoy video or phone consultations with healthcare professionals;
          </p>
        </li>
        <li>
          <p>
            to provide services utilising voice commands or recordings to assist nib members to
            assess nib's products or services such as locating healthcare professionals using voice
            commands, or where you have given consent to assist to verify your identity (such as
            nib's voice-based bot);
          </p>
        </li>
        <li>
          <p>
            to comply with our legal obligations, and to detect and prevent fraudulent activity in
            our business;
          </p>
        </li>
        <li>
          <p>to amend records to remove personal information;</p>
        </li>
        <li>
          <p>
            to improve the contents of, and the services we provide through, our websites and mobile
            applications; and
          </p>
        </li>
        <li>
          <p>and for other everyday business purposes that involve use of personal information.</p>
        </li>
      </ul>
      <Copy measure={false}>
        The above examples are a non-exhaustive overview only of how we may collect and use your
        personal information, and more detail may be provided to you in a separate privacy notice
        when you contact us or, where relevant, in a separate contractual arrangement with you.
      </Copy>
      <Heading size={{ xs: 4, sm: 3 }} component="h3" color="trueGreen">
        Legal basis for using your information
      </Heading>
      <Copy measure={false}>
        We ensure that we have an appropriate legal basis to deal with your personal information in
        these ways, including:
      </Copy>
      <ul>
        <li>
          <p>
            where you have provided your consent (such as when you provide your consent to receive
            marketing messages);
          </p>
        </li>
        <li>
          <p>
            where it is necessary for us to use your personal information in order to enter into or
            perform a contract (such as to send you a Product Disclosure Statement and Quotation in
            response to your product inquiry, to provide you with disability services under a signed
            agreement with you, or if we need to handle an insurance claim), or to protect your
            vital interests (such as to provide emergency medical assistance under a travel
            insurance policy you hold with us);
          </p>
        </li>
        <li>
          <p>
            where the applicable law permits us or requires us, including when we have a legal or
            regulatory obligation that we must comply with or is in the substantial public interest
            (such as to prevent fraud or money laundering) or we need to use your personal
            information to establish, exercise or defend legal rights (such as debt recovery) or
            whenever courts are acting in their judicial capacity; and
          </p>
        </li>
        <li>
          <p>
            where we need to use your personal information for our legitimate business interests
            (such as managing our business operations, developing and improving the products and
            services we offer, company re-structure or selling part of our business), and when we do
            so, we will consider your rights and interests in accordance with applicable law.
          </p>
        </li>
      </ul>
      <Heading size={{ xs: 4, sm: 3 }} component="h3" color="trueGreen">
        Direct Marketing and personalisation
      </Heading>
      <Copy measure={false}>
        Generally, we use your personal information for our business and activities, and in our
        efforts to improve our offerings to you. Examples include:
      </Copy>
      <ul>
        <li>
          <p>to offer and provide personalised health information, support and services;</p>
        </li>
        <li>
          <p>to offer and invite you to health management programs;</p>
        </li>
        <li>
          <p>
            to provide you with marketing communications and invitations and offers for products and
            services including new products or services that we or our third party business partners
            believe may be of interest to you and to assist in developing new products and services;
          </p>
        </li>
        <li>
          <p>
            and to provide improved services to you through our website, social media channels and
            to develop and improve the products and services we offer to you and/or to our
            customers;
          </p>
        </li>
      </ul>
      <Copy measure={false}>
        If we use your personal information to contact you and you would prefer us not to, or if you
        indicate a preference for a method of communication, please let us know and we will respect
        your preference. Please contact nib using the contact information at the end of this policy
        or via the method below:
      </Copy>
      <ul>
        <LiMargined>
          <Copy measure={false}>
            <strong>Australian Residents Health Insurance</strong>
          </Copy>
          <Copy measure={false}>
            <Link href="https://my.nib.com.au/login" target="_blank" rel="noopener noreferrer">
              my.nib.com.au/login
            </Link>
          </Copy>
        </LiMargined>
        <LiMargined>
          <Copy measure={false}>
            <strong>New Zealand Residents – Life and Health Insurance</strong>
          </Copy>
          <Copy measure={false}>
            <Link
              href="https://health.nib.co.nz/contact-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              health.nib.co.nz/contact-us
            </Link>
          </Copy>
        </LiMargined>
        <LiMargined>
          <Copy measure={false}>
            <strong>nib Travel Insurance</strong>
          </Copy>
          <Copy measure={false}>
            <Link
              href="https://www.nibtravelinsurance.com.au/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.nibtravelinsurance.com.au/contact
            </Link>
          </Copy>
        </LiMargined>
        <LiMargined>
          <Copy measure={false}>
            <strong>Iman Australia Health Plans and International Workers & Students</strong>
          </Copy>
          <Copy measure={false}>
            <Link href="https://my.nib.com.au/login" target="_blank" rel="noopener noreferrer">
              my.nib.com.au/login
            </Link>
          </Copy>
        </LiMargined>
        <LiMargined>
          <Copy measure={false}>
            <strong>World Nomads</strong>
          </Copy>
          <Copy measure={false}>
            <Link
              href="https://pp.worldnomads.com/contact-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              pp.worldnomads.com/contact-us
            </Link>
          </Copy>
        </LiMargined>
        <LiMargined>
          <Copy measure={false}>
            <strong>Travel Insurance Direct</strong>
          </Copy>
          <Copy measure={false}>
            <Link
              href="https://www.travelinsurancedirect.com.au/customer-contact "
              target="_blank"
              rel="noopener noreferrer"
            >
              www.travelinsurancedirect.com.au/customer-contact
            </Link>
          </Copy>
        </LiMargined>
        <LiMargined>
          <Copy measure={false}>
            <strong>nib Thrive</strong>
          </Copy>
          <Copy measure={false}>
            <Link
              href="https://www.nib.com.au/thrive/contact-us "
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.nib.com.au/thrive/contact-us
            </Link>
          </Copy>
        </LiMargined>
        <LiMargined>
          <Copy measure={false}>
            <strong>OrbitProtect</strong>
          </Copy>
          <Copy measure={false}>
            <Link
              href="https://orbitprotect.com/contact-us/ "
              target="_blank"
              rel="noopener noreferrer"
            >
              https://orbitprotect.com/contact-us
            </Link>
          </Copy>
        </LiMargined>
      </ul>
      <Copy measure={false}>
        When your choice is to continue to deal with us and in circumstances where you have been
        provided a functional unsubscribe facility and have not unsubscribed, we take it that you
        agree to and consent to us using your personal information, providing we follow the approach
        we explain in this Privacy Policy and comply with the law.
      </Copy>
    </Stack>
  );
};

const StoringPersonalInformationSection = (): JSX.Element => {
  return (
    <Stack space={{ xs: 2, sm: 3 }}>
      <Heading size={{ xs: 3, sm: 2 }} component="h2" color="trueGreen">
        Storing and disclosing your personal information
      </Heading>
      <Copy measure={false}>
        Personal information is retained during the time we need it for the identified purposes, to
        the extent necessary for purposes reasonably related to those identified purposes (for
        example, resolving disputes) or as required by law.
      </Copy>
      <Heading size={{ xs: 4, sm: 3 }} component="h3" color="trueGreen">
        Who we disclose your personal information to{' '}
      </Heading>
      <Copy measure={false}>
        In using and storing your personal information, we may pass on your personal information,
        including outside the country of collection:
      </Copy>
      <ul>
        <li>
          <p>
            to third parties, like our consultants, agents, contractors and service providers, and
            those that act as data controllers, processors or analysts, information technology
            providers, auditors or external advisers;
          </p>
        </li>
        <li>
          <p>
            to others who may be involved in yourcare, or in respect of disability services, the
            provision of support to you;
          </p>
        </li>
        <li>
          <p>
            to any intermediaries, including your agent, adviser, broker, representative or person
            acting on your behalf;
          </p>
        </li>
        <li>
          <p>
            to your employer or group administrator, if you are an international workers member
            (such as an IMAN member or if you are part of the PALM scheme) or a member of a
            workplace or association insurance plan, in order to administer that plan or where
            determined necessary or reasonable to do so, including in connection with any suspected
            unlawful activity associated with your insurance cover;
          </p>
        </li>
        <li>
          <p>
            to other insurers, reinsurers, insurance investigators and claims or insurance reference
            services, brokers, loss assessors, financiers;
          </p>
        </li>
        <li>
          <p>
            if you hold a travel insurance policy with us, to banks, foreign currency providers,
            goods replacement suppliers, transport and accommodation providers, travel agents and
            consultants, medical or health service providers or practitioners, emergency assistance
            providers, security providers, witnesses, translators or any other third parties
            involved in the processing, investigation or settling of a claim;
          </p>
        </li>
        <li>
          <p>
            if you are an authorised representative or a supplier of the nib Group, to credit
            providers or credit reporting agencies;
          </p>
        </li>
        <li>
          <p>
            to other companies in the nib Group, including those located in Australia, New Zealand,
            the Republic of Ireland, United Kingdom, the United States, the Philippines, Brazil and
            the Cayman Islands;
          </p>
        </li>
        <li>
          <p>
            to any of our nib Group strategic, distribution and whitelabel partners where authorised
            or required;
          </p>
        </li>
        <li>
          <p>
            where relevant, to a potential or actual third party purchaser of our business or
            assets;
          </p>
        </li>
        <li>
          <p>to debt collection agencies and other parties that assist with debt recovery;</p>
        </li>
        <li>
          <p>
            where relevant, to local registration boards and professional and industry bodies and
            associations, or to external dispute resolution bodies or other third parties involved
            in the management and resolution of complaints;
          </p>
        </li>
        <li>
          <p>
            for legal or safety reasons or other special circumstances, such as in order to comply
            with a legal or regulatory obligation to protect your vital interests;
          </p>
        </li>
        <li>
          <p>
            where we have a legitimate purpose (such as to manage our business operations or to
            conduct data analytics to improve our offerings);
          </p>
        </li>
        <li>
          <p>
            to any person authorised by you, or to others you have nominated, to access information
            in connection with an insurance policy you hold with us;
          </p>
        </li>
        <li>
          <p>
            to other persons who are insured on your policy to confirm, for example, that full
            disclosure has been made to us, or to ensure that the policyholder or person managing a
            claim has details of claims made on the policy, including any personal information used
            to make a claim determination;
          </p>
        </li>
        <li>
          <p>
            and in additional ways the applicable law permits us, or that you may also agree to.
          </p>
        </li>
      </ul>
      <Copy measure={false}>
        We may also disclose to third parties information from which information directly
        identifying individuals has been removed (such as aggregated, anonymous or pseudonymised
        information) so that your identity is not ascertainable. This includes sharing data
        analytics results.
      </Copy>
      <Heading size={{ xs: 4, sm: 3 }} component="h3" color="trueGreen">
        We may disclose your personal information overseas
      </Heading>
      <Copy measure={false}>
        When we pass on, transfer or share your personal information in this way, we take steps to
        ensure it is treated in the same way that we would treat it, and that an adequate level of
        protection is in place in accordance with relevant privacy and data protection laws.
      </Copy>
      <Copy measure={false}>
        For example, if your personal information is collected in the EEA and we (or third parties
        acting on our behalf) transfer personal information that we collect about you to countries
        outside of the EEA (such as when we need to collect or share this information with nib’s
        parent company located in Australia), your personal information may be subject to both
        Australian and European privacy requirements, and the steps that we take to protect your
        personal information include obtaining contractual commitments to comply with applicable
        privacy requirements (referred to under European data protection laws as “Standard
        Contractual Clauses”). If we transfer personal information from inside the UK to third
        countries outside the UK which have not been certified by the UK as having an adequate level
        of protection, steps we take to protect your personal information may include having an
        International Data Transfer Agreement in place, or using “Standard Contractual Clauses” with
        UK addendum. Depending on circumstances of the particular transfer, other steps we might
        take include transferring personal information to companies in the United States which are
        certified under the “Privacy Shield” (in the case of EU-US transfers of personal data).
      </Copy>
      <Copy measure={false}>
        Some businesses within the nib Group (e.g., our travel insurance businesses) have
        relationships with insurers and other entities overseas. The countries in which these
        recipients may be located will vary from time to time, but may include the United Kingdom,
        the United States, Canada, Malta, Denmark, and Brazil. In addition, some businesses in the
        nib Group may have relationships with overseas service providers (including in China) to
        help manage information technology needs. The types of information we disclose depends on
        your relationship with us and may include identifiable information associated with your
        insurance policy or other services you've engaged with, including claims and health
        information.
      </Copy>
      <Copy measure={false}>
        We may also disclose your personal information to health service providers and others we
        have business arrangements with overseas as necessary to enable them to offer their products
        and services to you, such as where you are covered by a travel insurance policy and require
        appropriate medical treatment and services while overseas (and if we do so, we will ensure
        we meet applicable data protection requirements, such as explicit consent, or protection of
        the vital interests of a data subject). We do our best to keep our records of your personal
        information up to date and accurate, and to delete or amend personal information that is no
        longer needed.
      </Copy>
    </Stack>
  );
};

const SecuritySection = (): JSX.Element => {
  return (
    <Stack space={{ xs: 2, sm: 3 }}>
      <Heading size={{ xs: 3, sm: 2 }} component="h2" color="trueGreen">
        How we protect your information
      </Heading>
      <Copy measure={false}>
        We use various systems and services including technical measures to safeguard the personal
        information we store, as part of our business systems and processes. We take steps to
        protect your personal information from misuse, interference or loss and unauthorised access,
        modification and disclosure with appropriate safeguards and security measures. This includes
        in storage and when we transfer or share it, such as across borders, to ensure compliance
        with international data protection standards relevant to our travel, overseas student and
        worker health insurance policies.
      </Copy>
      <Copy measure={false}>
        All our staff are trained to understand privacy and data protection requirements. We also
        conduct Privacy Impact Assessments on new systems and processes, to ensure we are adhering
        with privacy requirements and putting the right protections in place when making changes. We
        have teams and programmes in place to check our protections are working and remain up to
        date, including our protections to prevent and detect cyber threats. We are regularly
        audited to provide an independent assessment of our protection measures and help us to
        continue to maintain standards of personal information management and protection.
      </Copy>
      <Copy measure={false}>
        While we take steps to protect your personal information when you send it to us, you should
        keep in mind that no Internet transmission is ever completely secure or error- free. If you
        provide any personal information to us via our online services (including email or a
        web-based service through a portal), or if we provide information to you by these means, the
        privacy, security and integrity of any data transfer over the internet cannot be guaranteed.
        When you share information with us (such as over the internet, or sending us an email), it
        is at your own risk as factors beyond our control include the security of the device and/or
        program you use to communicate with us, and steps you take to protect your login details and
        password. If you reasonably believe that there has been unauthorised use or disclosure of
        your personal information, please contact us (see below).
      </Copy>
      <Copy measure={false}>
        Our website may contain links or references to other websites or social media platforms not
        subject to this Privacy Policy. You should check their own privacy policies before providing
        your personal information.
      </Copy>
    </Stack>
  );
};

const AccessPersonalInformationSection = (): JSX.Element => {
  return (
    <Stack space={{ xs: 2, sm: 3 }}>
      <Heading size={{ xs: 3, sm: 2 }} component="h2" color="trueGreen">
        Accessing and correcting your personal information
      </Heading>
      <Heading size={{ xs: 4, sm: 3 }} component="h3" color="trueGreen">
        nib Group
      </Heading>
      <Copy measure={false}>
        You may wish to contact nib Group to access your personal information, to seek to correct
        it, delete it or to make a complaint about privacy (and under applicable privacy laws you
        may have rights of access to and correction of your personal information) or to manage your
        communication preferences. Our privacy email contact address for our Group Privacy Officer
        is <Link href="mailto:privacyofficer@nib.com.au">privacyofficer@nib.com.au</Link> and
        further contact details for nib Group are set out below.
      </Copy>
      <UlBlank>
        <li>nib holdings Limited</li>
        <li>22 Honeysuckle Drive</li>
        <li>Newcastle NSW 2300</li>
        <li>AUSTRALIA</li>
        <li>Phone: 13 14 63 (within Australia)</li>
        <li>+61 2 4914 1100 (outside Australia)</li>
        <li>Attention: Group Privacy Officer</li>
      </UlBlank>
      <Copy measure={false}>
        You can also contact your local nib entity. We will respond to your request for access to
        personal information we hold about you within the timeframes required by applicable laws
        and/or as soon as we reasonably can, including notifying you if we are unable to provide
        access (such as when we no longer hold the information) or if we are permitted by applicable
        law to refuse access.
      </Copy>
      <Copy measure={false}>
        Generally, we do not impose any charge for a request for access, but where permitted to do
        so by applicable law, we may charge you a reasonable fee for the retrieval costs associated
        with providing you with access.
      </Copy>
      <Copy measure={false}>
        You must keep us informed of any changes to your information, like your contact details or
        address, to ensure your details remain up-to-date.
      </Copy>
      <Copy measure={false}>
        For complaints about privacy, we will establish in consultation with you a reasonable
        process for seeking to resolve your complaint, including time frames provided by applicable
        laws.
      </Copy>
      <Heading size={{ xs: 4, sm: 3 }} component="h3" color="trueGreen">
        European Economic Area (EEA) and United Kingdom
      </Heading>
      <Copy measure={false}>
        <strong>If you are located in the European Economic Area (EEA)</strong> and require further
        information about how we deal with your personal data under EEA data protection laws, please
        contact us at:
      </Copy>
      <UlBlank>
        <li>nib Travel Services Ireland Limited / nib Travel Services Europe Limited</li>
        <li>City Quarter Building</li>
        <li>Lapps Quay, Cork, IRELAND.</li>
        <li>
          <Link href="mailto:PrivacyEU@nibtravel.com">PrivacyEU@nibtravel.com</Link>
        </li>
      </UlBlank>
      <Copy measure={false}>
        Under EU laws, additional rights may also be available to you about the way we handle your
        personal data, including the right to complain to your local supervisory authority:
      </Copy>
      <UlBlank>
        <li>Data Protection Commission</li>
        <li>21 Fitzwilliam Square</li>
        <li>South Dublin 2 D02RD28 IRELAND</li>
        <li>
          <Link href="https://www.dataprotection.ie" target="_blank" rel="noopener noreferrer">
            www.dataprotection.ie
          </Link>
        </li>
        <li>
          <Link href="mailto:dpo@dataprotection.ie">dpo@dataprotection.ie</Link>
        </li>
      </UlBlank>
      <Copy measure={false}>
        If you are located in the United Kingdom and are not satisfied with our or your insurer’s
        response, or believe we or your insurer are not processing data in accordance with the law,
        you can complain to your local Data Protection Commissioner:
      </Copy>
      <UlBlank>
        <li>Information Commissioners Office</li>
        <li>Wycliffe House</li>
        <li>Water Lane</li>
        <li>Wilmslow</li>
        <li>Cheshire</li>
        <li>SK9 5AF</li>
      </UlBlank>
      <Copy measure={false}>
        Other rights which may be available to you under UK and EU laws include:
      </Copy>
      <ol type="a">
        <li>
          <p>
            Right to access your personal data (you have the right to request a copy of your
            personal data that we hold about you, and please see further above);
          </p>
        </li>
        <li>
          <p>
            Right to rectification (as noted, if you believe that the information we hold about you
            is inaccurate or incomplete, please let us know so that we can rectify any gap or
            inaccuracy);
          </p>
        </li>
        <li>
          <p>
            Right to erasure, or right to be forgotten (in certain circumstances, you have the right
            to request that we erase your personal data, such as if your personal data is no longer
            necessary for the purpose of original collection);
          </p>
        </li>
        <li>
          <p>
            Right to restriction of processing (in certain circumstances, you have the right to
            request that we restrict the processing of your personal data, and please see further
            above for example in relation to how you may opt out of our marketing communications);
          </p>
        </li>
        <li>
          <p>
            Right to data portability (in certain circumstances, you can request that personal data
            we hold about you be transmitted directly to another organisation);
          </p>
        </li>
        <li>
          <p>
            Right to object (you may have the right to object to our processing of your personal
            data, such as in relation to direct marketing communications and your right to opt out
            as outlined above, or to otherwise object unless we establish that we have legitimate
            grounds for the processing which outweighs your privacy rights);
          </p>
        </li>
        <li>
          <p>
            Rights relating to automated decision making (you have the right to ask us to not to be
            subject to a decision based solely on automated processing including profiling which
            produces legal effects or other similar significant effects concerning you).
          </p>
        </li>
      </ol>
      <Heading size={{ xs: 4, sm: 3 }} component="h3" color="trueGreen">
        nib Securities
      </Heading>
      <Copy measure={false}>
        <strong>If you hold nib securities</strong>, we have outsourced nib holding limited’s share
        registry function to Computershare Investor Services Pty Limited, which has its own privacy
        policy. For more information about how Computershare deals with personal information, or to
        make a complaint about Computershare’s handling of your personal information in your
        capacity as a security holder, please refer to its privacy policy at
        <Link href="https://www.computershare.com/au" target="_blank" rel="noopener noreferrer">
          {' '}
          www.computershare.com/au
        </Link>{' '}
        or by contacting Computershare (telephone: 1800 804 985, or by email{' '}
        <Link href="mailto:privacy@computershare.com.au">privacy@computershare.com.au</Link>).
      </Copy>
      <Copy measure={false}>
        <strong>Last updated</strong>: July 2024
      </Copy>
    </Stack>
  );
};

const PrivacyPolicyPage = () => (
  <Layout>
    <Helmet>
      <title>{title} | nib</title>
      <meta name="description" content={metaDescription} />
    </Helmet>
    <Section>
      <Stack space={{ xs: 4, sm: 6 }}>
        <HeadingSection />
        <CollectPersonalInformationSection />
        <UsingPersonalInformationSection />
        <StoringPersonalInformationSection />
        <SecuritySection />
        <AccessPersonalInformationSection />
      </Stack>
    </Section>
  </Layout>
);

export default metrics({ pageName: 'privacy-policy' })(PrivacyPolicyPage);
